import React, {useEffect} from 'react';

import ReactNotification from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css'
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';


import Login from './student/login'
import ForgetPassword from './student/forgotpassword'

import ProtectedView from './protected_view'
import {LicenseTimeout} from './component/notify'
import {  LocalUrl } from './component/include';

import error403 from './settings/error403';
import error500 from './settings/error500';
import Error404 from './settings/error404';

const App =()=>{

  /* const checkLicense=()=>{
    //prevent working from localhost
    if(window.location.hostname ==='localhost'){
        return false;
    }else if(String(LocalUrl) ===String(window.location.hostname)){
        return true;
      }else{
        return false;
      }
    }
    
    useEffect(()=>{
    setTimeout(() => {
    if(checkLicense()===false){
      document.getElementById('openLicenseModal').click()
    //timeup logout user
    }
    }, 4000)
    }, []) */



  return (<>

<a href="#!" data-toggle="modal" id='openLicenseModal' data-target='#licenseModal' ></a>
    <LicenseTimeout   /> 
    <ReactNotification />
   <Router  >
    <Switch>
    <Route exact path="/" component={Login} />
    {/* <Route exact path="/forgot_password" component={ForgetPassword} /> */}
    <ProtectedView />
<Route exact path="/error403" component={error403} />
<Route exact path="/error500" component={error500} />
<Route exact  component={Error404} />
    </Switch>
      </Router>
 </>
    );
  }
export default App;
