import React, {useState, useEffect} from 'react'
import Template from '../component/template'
import {LoadEffect}  from '../component/loader'
import axios from 'axios'
import { Alerts } from '../component/notify'
import PageHeader from '../component/pageheader'
import { ImagesUrl, ServerUrl, Token, config } from '../component/include'

import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';

 const ELibrary =()=>{   
    
const [data, setData] =  useState([]);   
const [notice, setNotice] = useState({
    isLoading : false, 
    isDataFetching: false,
}); 


const  handleFetchData =()=>{
    const fd = new FormData();
    fd.append('jwt', Token);
    let url = ServerUrl + '/fetch_controller/tbl_elibrary'
    setNotice({...notice, 
        isDataFetching: true
    });
    axios.post(url, fd, config)
    .then(result =>setData(result.data))
    .catch((error)=>{
        Alerts('Error!', 'danger', JSON.stringify(error.message)) 
    }).finally(()=>{
        setNotice({...notice, 
            isDataFetching: false
        })
    })    
        }

useEffect(()=>{
    handleFetchData();
},[]);

const openLibraryBooks =(cell)=>{

    const fd = new FormData();
    var sql = 'update tbl_elibrary set viewNo = viewNo + 1 where code ="'+cell.code+'"'
    fd.append('jwt', Token);
          fd.append("sql", sql);
          let url = ServerUrl+'/fetchBySql_controller'
          axios.post(url, fd, config)
            .catch((error)=>{
                Alerts('Error!', 'danger', JSON.stringify(error.message)) 
            }).finally(()=>{
                window.open(ImagesUrl+'/library/'+cell.fileLink, '_blank')
            })
        }


 
const tableHeader = [
    {dataField: 'title',  text: 'Title',  editable: false},
    {dataField: 'fileLink', text: 'File Link', editable: false, formatter:(cell, row)=><button type='button' onClick={()=>openLibraryBooks(row)} className='btn btn-sm btn-inverse'><i className='fa fa-eye'></i> View</button>}
 ];


const TableRecord=(props)=>{


     const { SearchBar } = Search;
     
     const customTotal = (from, to, size) => (
        <span >&nbsp;Showing { from } to { to } of { size } items</span>
      );
     const options = {
        showTotal: true,
      paginationTotalRenderer: customTotal,
      sizePerPageList: [{text: '7', value: 7}, {text: '15', value: 15}, {text: '20', value: 20}, {text: '50', value: 50}, {text: '100', value: 100},
      { text: 'All', value: props.data.length }]
       
      };
     return  <ToolkitProvider search columnToggle 
                 keyField='ID' data={ props.data } columns={ props.columns } >
                     
                     {
                         props => (
                         <div >
                           <div className="form-group">
                             <SearchBar
                             { ...props.searchProps } style={{height:'40px'}} className="form-control" placeholder="Type to search!!!" />
                                              
 </div>
                             
                 <BootstrapTable
                             { ...props.baseProps }
                                         striped
                                     hover
                                     condensed
                                     noDataIndication={<div className="alert alert-danger background-danger">No Record Found</div>}
                                 pagination={paginationFactory(options) }
                                
                             />
                         </div>
                         )
                     }
  </ToolkitProvider>
  }




        
        return (  <Template menu="Library" submenu='E-Library'>
   {notice.isLoading ?<LoadEffect className="floatLoad" /> : "" } 
   <div  className="main-body">
                <div className="page-wrapper">  
                   {/* <!-- Page-header start --> */}

        <PageHeader title="E-Library">
        <li className="breadcrumb-item"><a  href="#!"> <i className="fa fa-fw fa-book"></i> </a>
                            </li>
                            <li className="breadcrumb-item"><a href="#!">Library</a> </li>

<li className="breadcrumb-item"><a href="#!">eLibrary</a> </li>
                    </PageHeader>
{/* The Page Body start here */}
                <div className="page-body">              

                <div className="row  ">
		

    <div className="col-sm-12">
    <div className="card z-depth-0">
        <div className="card-header">
            <h5>Listing All e-Books</h5>
            <div className="card-header-right">
                <ul className="list-unstyled card-option">
                    <li><i className="feather icon-maximizes full-card"></i></li>
                    <li><i className="feather icon-minus minimize-card"></i></li>
                    <li><i className="feather icon-trash-2 close-card"></i></li>
                </ul>
            </div>
        </div>

        

<div className="card-block">
<div className="col-md-12 table-responsive">

        {<TableRecord data={data}  columns={tableHeader}  /> }
</div></div>
</div>
</div>

</div>
</div>
          {/* The Page Body close here */}

            </div>
            </div>

</Template> 
 );
        }


export default React.memo(ELibrary)