import React, {useState, useEffect} from 'react'
import Template from '../component/template'
import {LoadEffect}  from '../component/loader'
import axios from 'axios'
import { Alerts} from '../component/notify'
import PageHeader from '../component/pageheader'
import {ServerUrl,  studentCode,  Token,  config} from '../component/include'
import {longDate} from '../component/globalFunction'

const MyReview =()=> {
    
const [notice, setNotice] = useState({
    isLoading : false, 
    isDataFetching: false,
})
    
    const [review, setReview]= useState([])    
    
    

    const fetchReview =()=>{

        let sql = "Select  review, dateReview, reviewBy from tbl_student_review where visibleToStudent='YES' and studentCode ='"+studentCode+"' order by ID DESC"
        
        const fd = new FormData();
        fd.append('jwt', Token);
        fd.append("sql", sql);
        let url = ServerUrl+'/fetchBySql_controller'
        axios.post(url, fd, config).then(result=>{ 
            setReview(result.data)
        })
        .catch((error)=>{
        Alerts('Error!', 'danger', error.message)
        })

        }


useEffect(()=>{
    fetchReview()
},[]);



        return (  
            <React.Fragment>
   <Template menu='Dashboard' submenu=''>{notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
   <div  className="main-body">
                <div className="page-wrapper">
                 
                   {/* <!-- Page-header start --> */}

        <PageHeader title="My Review">
        <li className="breadcrumb-item"><a  href="#!"> <i className="fa fa-fw fa-book"></i> </a>
                            </li>
                            <li className="breadcrumb-item"><a href="#!">Student</a> </li>
                            <li className="breadcrumb-item"><a href="#!">Review</a> </li>
                    </PageHeader>
{/* The Page Body start here */}
                <div className="page-body">
              
     
                <div  className="row  ">
                <div className="col-sm-12">
                <div className="card latest-activity-card">
                    <div className="card-header">
                        <h5>Student Review</h5>
                    </div>
                    <div className="card-block">
                        <div className="latest-update-box">

            {review.length!==0?review.map((data, index)=>
                            <div key={index} className="row p-b-30">
                                <div className="col-auto text-right update-meta">
                                    <p className="text-muted m-b-0 d-inline">{longDate(data.dateReview)}</p>
                                    <i className="feather icon-file-text bg-simple-c-blue update-icon"></i>
                                </div>
                                <div className="col">
                                    <h6>By: {data.reviewBy}</h6>
                                    <p className="text-muted m-b-0">{data.review}</p>
                                </div>
                            </div>):' There are no any review yet'}


                        </div>
                    </div>
                </div>  
                </div>



                            
                            </div>






   </div>
          {/* The Page Body close here */}

            </div>
            </div>

</Template> 
</React.Fragment>
 );
        
}

export default React.memo(MyReview) 