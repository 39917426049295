import React, {useEffect, useState} from 'react';
import {Alerts} from './component/notify'
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';


import ViewTimetable from './timetable/view_timetable'
import LessonPlan from './timetable/lesson_plan'
import Holidays from './timetable/holidays_list'


import eLibrary from './library/e-library'
import BookReturn from './library/issues_return'
import Books from './library/books'



import StudentAttendance from './attendance/display_student_attendance'
import StudentArrival from './attendance/student_arrival_departure_late'

import MockResult from './exam/mock_result'
import ExamSchedule from './exam/exam_schedule'
import ExamResult from './exam/exam_result'
import Transcript from './exam/transcript'


import FamilyFees from './fees/family_fees'
import FeesPayment from './fees/fees_payment'


import Dashboard from './dashboard/dashboard'

import Profile from './student/profile'
import Noticeboard from './student/noticeboard'
import Review from './student/review'
import ChangePassword from './student/change_password'
import Error404 from './settings/error404';


import axios from 'axios'
import { SessionTimeout } from './component/notify'
import Cookies from 'js-cookie'
import {config,  familyCode, classID, sectionID, studentName, Token,  ServerUrl, studentCode} from './component/include'
import {encrypt, decrypt} from './component/authentication'
import hostel from './hostel/hostel';
import room_occupant from './hostel/room_occupant';
import midTerm_result from './exam/mid-term_result';
import print_mid_term_result from './exam/print_mid_term_result';
import print_class_result from './exam/print_class_result';
import tickets from './tickets/tickets';
import readTicket from './tickets/readTicket';
import submitTicket from './tickets/submitTicket';


const ProtectedView =()=>{



const openWarningModal =(interver)=>{
      let openWarning =   document.getElementById('openTimeoutModal')
              openWarning.click();
              clearInterval(interver)
}
 

const handleReconnect =()=>{

  let closeWarning =   document.getElementById('btnLogoutsession')
  closeWarning.click();
           
const fd = new FormData();

fd.append('code', studentCode)
  fd.append('studentName', studentName)
  fd.append('classID', classID)
  fd.append('sectionID', sectionID)
  fd.append('familyCode', familyCode)
fd.append('jwt', Token)


    let url = ServerUrl+'/login_refresh_student_controller.php?tablename=tbl_refresh_login_user';
		axios.post(url, fd, config)
		.then(response =>{
	if (response.data.type ==='success'){

          //1000 * 60 * SESSION_IDEL_MINUTES
         
		var inSixtyMinutes = Date.now() + 4199 * 60 * 1000;
		var inOneMinutes = Date.now() + 418 * 60 * 1000;
		var inOneHours = new Date(new Date().getTime() + 419 * 60 * 1000);
    
		Cookies.set("_gaatstexpt", inOneMinutes, { expires: inOneHours});
		Cookies.set("_gaatstbexpt", inSixtyMinutes, { expires: inOneHours });


  Cookies.set('gaatstkauscd', encrypt(response.data.code), {expires: inOneHours })
  Cookies.set('gaatstkamtk', encrypt(response.data.jwt),  {expires: inOneHours })   
  Cookies.set('gaatstkarfsh', encrypt(response.data.userToken),  {expires: inOneHours })
  Cookies.set('gaatstkstnm', encrypt(response.data.studentName),  {expires: inOneHours })

  Cookies.set('gaatfmcdk', encrypt(response.data.familyCode),  {expires: inOneHours })

  Cookies.set('gaatsclkysd', encrypt(response.data.classID),  {expires: inOneHours })
  Cookies.set('gaatssckayd', encrypt(response.data.sectionID),  {expires: inOneHours })

      Alerts('Info!', 'success', 'Successfuly reconnected')

        window.location.reload()
             } else {
				handleLogout()
			}  
       

		})
		.catch((error)=>{
			Alerts('Error!', 'danger', error.message)
     handleLogout()
		})
}



const handleLogout =()=>{
Alerts('Info!', 'default', 'Your session has expired')
window.location.href='/?refferer='+window.location.href
}


const startWarning = ()=>{
  var interver;
  interver = setInterval(() => {
    //one minute before expiry time
 var beforeExpired = Number(Cookies.get('_gaatstbexpt'))
  if(beforeExpired < Date.now()){
    openWarningModal(interver)
//timeout in one minutes time
  }
}, 5000)
}
  
     
useEffect(()=>{
  if (localStorage.getItem("gaatstlcksr") !== null) {
    document.getElementById('openlockscreenModal').click()
 }
})


useEffect(()=>{
  //fetchContent()
startWarning()
   
   setInterval(() => {
 var expiredTime = Number(Cookies.get('_gaatstexpt'))
  if(expiredTime < Date.now()){
    handleLogout()
    //timeup logout user
  }
}, 5000)
}, [])

    return ( <>
    <a href="#!" data-toggle="modal" id='openTimeoutModal' data-target='#timeoutModal' ></a>
    <SessionTimeout  Logout={handleLogout} Connected ={handleReconnect} /> 
    
    <Router  >
      <Switch>


      <Route exact path="/dashboard" component={Dashboard} />
    
    <Route exact path="/timetable/class_timetable" component={ViewTimetable} /> 
    <Route exact path="/timetable/class_lesson_plan" component={LessonPlan} /> 
    <Route exact path="/timetable/holidays" component={Holidays} /> 
    
    
    <Route exact path="/library/elibrary" component={eLibrary} /> 
    <Route exact path="/library/books_report" component={BookReturn} /> 
    <Route exact path="/library/books" component={Books} />
    
    <Route exact path="/attendance/student_attendance" component={StudentAttendance} /> 
    <Route exact path="/attendance/student_arrival_departure" component={StudentArrival} /> 
    
    <Route exact path="/exam/exam_schedule" component={ExamSchedule} />  
    <Route exact path="/exam/mytranscript" component={Transcript} /> 

    <Route exact path="/exam/mid_term" component={print_mid_term_result} /> 

    <Route exact path="/exam/myresult" component={print_class_result} />

    {/* 
    <Route exact path="/exam/mock_result" component={MockResult} />  */}

    <Route exact path="/fees/family_fees" component={FamilyFees} /> 
    <Route exact path="/fees/fees_payment" component={FeesPayment} /> 
    
    
    <Route exact path="/student/profile" component={Profile} /> 
    <Route exact path="/student/review" component={Review} /> 
    <Route exact path="/student/noticeboard" component={Noticeboard} /> 
   {/*  <Route exact path="/student/change_password" component={ChangePassword} />  */}

   <Route exact path="/tickets" component={tickets} /> 
        <Route exact path="/tickets/read/:code" component={readTicket} /> 
    <Route exact path="/tickets/create" component={submitTicket} /> 

    <Route exact path="/hostel/allocation" component={hostel} /> 
    <Route exact path="/hostel/history" component={room_occupant} /> 
        <Route exact  component={Error404} />
      </Switch>
      </Router>
      </>
    );
  
}
export default ProtectedView;
