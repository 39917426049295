import React, {useState, useEffect, useCallback} from 'react'
import Template from '../component/template'
import {LoadEffect, TableLoader}  from '../component/loader'
import axios from 'axios'
import { Alerts, WarningModal} from '../component/notify'
import PageHeader from '../component/pageheader'
import {ServerUrl,  Token,  studentCode, config} from '../component/include'
import Select from 'react-select'

import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { longDate } from '../component/globalFunction'

const StudentDeparture =()=> {
    
    const [notice, setNotice] = useState({
        isLoading : false, 
        isDataFetching: false,
    }); 
    
    const [allArrival, setAllArrival]= useState([])

   
    const fetchAllArrival =(current)=>{
        const fd = new FormData();
        fd.append("jwt", Token);
        let sql = "Select a.ID, a.code, s.studentName, s.admissionNumber, a.arrivalType, a.arrivalDate,	a.arrivalTime,	a.description from tbl_student_arival_departure a, tbl_students s  where a.student = s.code and a.student = '"+studentCode+"' and sessionYear = '"+current+"'"

        let url = ServerUrl+'/fetchBySql_controller'      
        fd.append("sql", sql)
        axios.post(url, fd, config).then(result =>setAllArrival(result.data)   
    ).catch((error)=>{
        Alerts('Error!', 'danger', error.message)
        })
    }


    const tableHeader = [
        
        {dataField: 'studentName', text: 'Student',  editable:false, formatter: (cell, row)=>`(${row.admissionNumber}) ${cell}  `},
        {dataField: 'arrivalType', text: 'Type'},       
        {dataField: 'arrivalDate', text: 'Date', formatter:(cell, row)=>longDate(cell)},
    {dataField: 'arrivalTime', text: 'Time'}, 
    {dataField: 'description', text: 'Description'}
     ];

     
    const TableRecord=(props)=>{
        
         const { SearchBar } = Search;
     
         const customTotal = (from, to, size) => (
            <span >&nbsp;Showing { from } to { to } of { size } items</span>
          );
         const options = {
             showTotal: true,
         paginationTotalRenderer: customTotal,
         sizePerPageList: [{text: '100', value: 100}, {text: '200', value: 200}, {text: '500', value: 500},
         { text: 'All', value: props.data.length }]
            
           };
      
         return <ToolkitProvider search columnToggle 
                     keyField='code' data={ props.data } columns={ props.columns } >
                         
                         {
                             props => (
                             <div >
                               <div className="form-group">
                                 <SearchBar
                                 { ...props.searchProps } style={{height:'40px'}} className="form-control" placeholder="Type to search!!!" />
                                 &nbsp;
                           
                                 </div>
                                 
                          
                 
                                 <BootstrapTable
                                 { ...props.baseProps }
                                             striped
                                         hover
                                         condensed
                                         noDataIndication={<div className="alert alert-danger background-danger">No Record Found</div>}
                                     pagination={paginationFactory(options) }
                                   
                                 />
                             </div>
                             )
                         }
      </ToolkitProvider>
      }
     
      const  handleSession =()=>{

        var sql ="Select  startYear from tbl_school_session where status ='Active'"
        const fd = new FormData();
        fd.append("sql", sql);
        fd.append("jwt", Token);
        let url = ServerUrl+'/fetchBySql_controller';
        axios.post(url, fd, config)
        .then(result => {
            if(result.data.length!==0){
            fetchAllArrival(result.data[0].startYear) 
        }
        })
        .catch((error)=>{
            Alerts('Error!', 'danger', JSON.stringify(error.message)) 
        })
        }

useEffect(()=>{
handleSession()
},[]);

        return (  
            <React.Fragment>
   <Template menu='Attendance' submenu='Late Arrival & Early Departure'>{notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
   <div  className="main-body">
                <div className="page-wrapper">
                 
                   {/* <!-- Page-header start --> */}

        <PageHeader title="Student Arrival & Departure">
        <li className="breadcrumb-item"><a  href="#!"> <i className="fa fa-fw fa-calendar"></i> </a>
                            </li>
                            <li className="breadcrumb-item"><a href="#!">Attendance</a> </li>
                    <li className="breadcrumb-item"><a href="#!">Late Arrival & Early Departure</a> </li>
                    </PageHeader>
{/* The Page Body start here */}
                <div className="page-body">

                <div className="row  ">
				
		
	

	<div className="col-sm-12">
      
	<div className="card z-depth-0">
		<div className="card-header">
			<h5> <i className="fa fa-th" ></i> Student Records</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximizes full-card"></i></li>
					<li><i className="feather icon-minus minimize-card"></i></li>
					<li><i className="feather icon-trash-2 close-card"></i></li>
				</ul>
			</div>
		</div>
		<div className="card-block">
        <div className="col-md-12 table-responsive">

        {notice.isDataFetching ? <TableLoader />:
   <TableRecord data={allArrival}  columns={tableHeader}  />} 

    </div></div>
</div>


</div>

</div>
	

   </div>
          {/* The Page Body close here */}

            </div>
            </div>

</Template> 
</React.Fragment>
 );
        
}

export default React.memo(StudentDeparture) 