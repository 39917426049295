import React, {useState, useEffect} from 'react'
import Template from '../component/template'
import {LoadEffect, TableLoader}  from '../component/loader'
import axios from 'axios'
import { Alerts} from '../component/notify'
import PageHeader from '../component/pageheader'
import {ServerUrl,  Token, config, ImagesUrl} from '../component/include'
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { longDate } from '../component/globalFunction'

const Noticeboard =()=> {
    
const [notice, setNotice] = useState({
    isLoading : false, 
    isDataFetching: false,
})
   
    const [noticeboard, setNoticeboard]= useState([])    
    
    const fetchNotice =()=>{
        var today = new Date().toISOString().slice(0,10);

        let sql = "Select  imageUrl, description, dateCreate, ID, createdBy from tbl_noticeboard where status ='Active' and  receiver = 'Only Student' or receiver = 'Everyone' and  expiry >= '"+today+"'"
        
        const fd = new FormData();
    fd.append('jwt', Token);
        fd.append("sql", sql);
        let url = ServerUrl+'/fetchBySql_controller'
        axios.post(url, fd, config).then(result=>{ 
            setNoticeboard(result.data)
        })
        .catch((error)=>{
        Alerts('Error!', 'danger', error.message)
        })

        }



const tableHeader = [
    {dataField: 'dateCreate', text: 'Date Posted', formatter:(cell)=>longDate(cell)},
        {dataField: 'createdBy', text: 'From'},
        {dataField: 'description', text: 'Message', formatter:(cell)=><span style={{display:'block',  width:400, whiteSpace:'normal', wordWrap:'break-word' }} dangerouslySetInnerHTML={{ __html: cell }}></span>},     
        {dataField: 'imageUrl', text: 'Action', formatter:(cell)=>cell!==''?<a href={ImagesUrl+'/noticeboard/'+cell} target="_new" className="btn btn-success btn-sm"     title="View"> <i className="fa fa-download"></i> View</a>:''}
 ];
 



 const TableRecord=(props)=>{


    
     const { SearchBar } = Search;
 
     const customTotal = (from, to, size) => (
        <span >&nbsp;Showing { from } to { to } of { size } items</span>
      );
     const options = {
         showTotal: true,
     paginationTotalRenderer: customTotal,
     sizePerPageList: [{text: '100', value: 100}, {text: '200', value: 200}, {text: '500', value: 500},
     { text: 'All', value: props.data.length }]
        
       };
  
     return <ToolkitProvider search columnToggle 
                 keyField='ID' data={ props.data } columns={ props.columns } >
                     
                     {
                         props => (
                         <div >
                           <div className="form-group">
                             <SearchBar
                             { ...props.searchProps } style={{height:'40px'}} className="form-control" placeholder="Type to search!!!" />
                             &nbsp;
                           
                         
                             </div>
                             
                      
             
                             <BootstrapTable
                             { ...props.baseProps }
                                         striped
                                     hover
                                     condensed
                                     noDataIndication={<div className="alert alert-danger background-danger">No Record Found</div>}
                                 pagination={paginationFactory(options) }
                                 
                             />
                         </div>
                         )
                     }
  </ToolkitProvider>
  }





useEffect(()=>{
    fetchNotice()
},[]);



        return (  
            <React.Fragment>
   <Template menu='Notice Board' submenu=''>{notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
   <div  className="main-body">
                <div className="page-wrapper">
                 
                   {/* <!-- Page-header start --> */}

        <PageHeader title="Noticeboard">
        <li className="breadcrumb-item"><a  href="#!"> <i className="fa fa-fw fa-bar-chart"></i> </a>
                            </li>
                            <li className="breadcrumb-item"><a href="#!">Student</a> </li>
                            <li className="breadcrumb-item"><a href="#!">Noticeboard</a> </li>
                    </PageHeader>
{/* The Page Body start here */}
                <div className="page-body">
              
     
              



<div className="row  ">   <div className="col-sm-12">
                               
                                                
<div className="card z-depth-0">
            <div className="card-header">
                <h5><i className="fa fa-th" ></i> Listing All Notice</h5>
                <div className="card-header-right">
                    <ul className="list-unstyled card-option">
                        <li><i className="feather icon-maximizes full-card"></i></li>
                        <li><i className="feather icon-minus minimize-card"></i></li>
                        <li><i className="feather icon-trash-2 close-card"></i></li>
                    </ul>
                </div>
            </div>
            <div className="card-block">
            <div className="col-md-12 table-responsive">

         {notice.isDataFetching ? <TableLoader />:
         <TableRecord data={noticeboard}  columns={tableHeader}  /> 
        } 

            </div></div>
    
    </div>
    
    </div>
    </div>




   </div>
          {/* The Page Body close here */}

            </div>
            </div>

</Template> 
</React.Fragment>
 );
        
}

export default React.memo(Noticeboard) 