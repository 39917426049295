import Cookies from 'js-cookie'
var CryptoJS = require('crypto-js')

export const FormatNumber = (num)=>{
    
    let newNumber = Number(num)
    return  newNumber.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}




export const studentName = Cookies.get('gaatstkstnm')?CryptoJS.AES.decrypt(Cookies.get('gaatstkstnm'), 'gaatschool').toString(CryptoJS.enc.Utf8):'null'
export const classID = Cookies.get('gaatsclkysd')?CryptoJS.AES.decrypt(Cookies.get('gaatsclkysd'), 'gaatschool').toString(CryptoJS.enc.Utf8):'null'
export const sectionID = Cookies.get('gaatssckayd')?CryptoJS.AES.decrypt(Cookies.get('gaatssckayd'), 'gaatschool').toString(CryptoJS.enc.Utf8):'null'

export const familyCode = Cookies.get('gaatfmcdk')?CryptoJS.AES.decrypt(Cookies.get('gaatfmcdk'), 'gaatschool').toString(CryptoJS.enc.Utf8):'null'
export const studentCode= Cookies.get('gaatstkauscd')?CryptoJS.AES.decrypt(Cookies.get('gaatstkauscd'), 'gaatschool').toString(CryptoJS.enc.Utf8):'null'
export const Token = Cookies.get('gaatstkamtk')?CryptoJS.AES.decrypt(Cookies.get('gaatstkamtk'), 'gaatschool').toString(CryptoJS.enc.Utf8):'null'
export const config = {
    headers: { 'content-type': 'multipart/form-data'}
} 
export const Currency = '₦'


export  const ServerUrl = 'https://app.gaatschool.com/controller';
export  const ImagesUrl = 'https://app.gaatschool.com/images'; 
export  const LocalUrl = 'learners.gaatschool.com'; 

 //export  const ServerUrl = 'http://192.168.64.3/kayus/gaat/controller';
//export  const ImagesUrl = 'http://192.168.64.3/kayus/gaat/images'; 

//export  const LocalUrl = 'localhost';  

export const schoolName = 'GAAT INTERNATIONAL SCHOOL, ABUJA'

 


